import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

const FAQSection = ({ faqs }) => {
	const [openIndex, setOpenIndex] = useState(null);

	const toggleAccordion = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<div className="max-w-2xl mx-auto p-2">
			{/* <h2 className="text-2xl font-bold mb-4 text-center">
				Frequently Asked Questions
			</h2> */}
			<div className="space-y-4">
				{faqs.map((faq, index) => (
					<div key={index} className="border-b">
						<button
							onClick={() => toggleAccordion(index)}
							className="w-full flex justify-between items-center py-4 text-left"
						>
							<span className="font-semibold text-lg">{faq.question}</span>
							<svg
								className={`w-5 h-5 transition-transform ${
									openIndex === index ? "transform rotate-180" : ""
								}`}
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M19 9l-7 7-7-7"
								/>
							</svg>
						</button>
						<div
							className={`overflow-hidden transition-max-height duration-300 ${
								openIndex === index ? "max-h-full" : "max-h-0"
							}`}
						>
							{/* <p className="py-2 text-gray-600">{faq.answer}</p> */}
							<ReactMarkdown
								className="py-2 text-gray-600 prose prose-sm sm:prose"
								children={faq.answer}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FAQSection;
